<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              內容管理中心
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'CollectionList' }">
              資料集管理
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'CollectionItemList', params: { id: collection.id }}">
              {{ collection.name }}
            </b-breadcrumb-item>

            <b-breadcrumb-item v-text="breadCrumbName" active>
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">{{ breadCrumbName }}-{{ branchName }}</h4>
      </div>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <div class="row d-flex mb-4 mb-xl-2 justify-content-end">
                <div
                  class="
                    col-12 col-xl-6
                    d-flex
                    flex-column flex-xl-row
                    align-items-center justify-content-xl-end
                  "
                >
                  <b-button
                      v-if="checkPermission([consts.COLLECTION_ITEM_CREATE]) && selectedItems.length > 0"
                      class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
                      variant="primary"
                      @click="changeRelationIsEnabled"
                  >
                    <i class="fa fa-plus"></i>更新狀態
                  </b-button>
                  <CollectionAddStaff
                    v-if="checkPermission([consts.COLLECTION_ITEM_CREATE])"
                    :customer="customer"
                    :branchId="branchId"
                    @add="addStaff"
                  ></CollectionAddStaff>
                  <b-input-group class="col-12 col-xl-4 mb-2">
                    <b-form-input v-model="keyword" placeholder="搜尋"></b-form-input>
                    <b-input-group-append>
                      <b-button @click="fetchCollectionItem"><i class="fa fa-search"></i ></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
              <b-table
                striped
                hover
                responsive
                :items="items"
                :fields="fields"
              >
                <template #head(checkbox)>
                  <b-form-checkbox
                      v-model="selectAll"
                      @change="toggleSelectAll"
                  >
                  </b-form-checkbox>
                </template>
                <template #cell(checkbox)="data">
                  <b-form-checkbox
                    v-model="data.item.selected"
                  ></b-form-checkbox>
                </template>
                <template #cell(avatar_url)="data">
                  <div class="d-flex align-items-center justify-content-center">
                    <b-avatar
                      :src="data.item.avatar_url"
                      variant="secondary"
                      size="2rem"
                    ></b-avatar>
                  </div>
                </template>
                <template #cell(relation_enable)="data">
                  <div class="d-flex">
                    <div v-if="data.item.relation_enable">
                      是
                    </div>
                    <div v-else>
                      否
                    </div>
                  </div>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.COLLECTION_ITEM_EDIT])"
                    variant="inverse-danger"
                    @click="handleDelete(data.item)"
                  >
                    <span class="mdi mdi-delete mr-1"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchCollectionItem"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>

</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import collectionApi from "@/apis/collection";
import truncateText from "@/utils/truncateText"
import {mapGetters, mapState} from "vuex";
import CollectionAddStaff from "@/pages/Dashboard/Collection/CollectionAddStaff.vue";

export default {
  components: {CollectionAddStaff},
  data() {
    return {
      consts: consts,
      breadCrumbName: '',
      branchName: '',
      keyword: '',
      branchId: null,
      showLoading: false,
      showBranchSelect: false,
      fieldsInit: false,
      fields: [
        {
          key: 'checkbox',
          label: ''
        },
        {
          key: "avatar_url",
          label: "",
        },
        {
          key: "employee_code",
          label: "員工編號",
        },
        {
          key: "name",
          label: "姓名",
          sortable: true,
        },
        {
          key: "job_level",
          label: "職稱",
        },
        {
          key: "service_unit",
          label: "單位",
        },
        {
          key: "dept_name",
          label: "部門",
        },
        {
          key: "relation_enable",
          label: "是否啟用",
        },
        {
          key: "actions",
          label: "管理"
        }
      ],
      items: [],
      collection: {
        id: null,
        name: null,
        code: null,
        description: null,
        config: {
          fields: [],
        },
      },
      collectionItem: {
        id: null,
        name: null,
      },
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      formUrl: null,
      selectAll: false,
      isInitialized: false,
      collectionField: null,
      customer: {},

    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("general", ["currentBranch", "myBranches"]),
    selectedItems() {
      return this.items.filter((item) => item.selected).map((item) => item.collection_relation_id)
    },
    isRelatedBranch() {
      return this.collection?.config?.related_branch
    },
    isRelatedStaff() {
      return this.collection?.config?.related_staff
    },
    branchOptions() {
      let options = this.myBranches
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          return {
            value: branch.id,
            text: branchName
          }
        })
      options = [
        {
          value: null,
          text: '無分店'
        },
        ...options
      ]
      return options
    },
  },
  watch: {
    selectedItems: {
      handler(newVal) {
        this.selectAll = newVal.length === this.items.length;
      },
      deep: true,
    },
  },
  async mounted() {
    this.collection.id = this.$route.params.id;
    this.collectionItem.id = this.$route.params.item_id;
    await this.fetchCollection();
    await this.fetchCollectionItem();
    await this.fetchItemStaffs();
    this.isInitialized = true
  },
  methods: {
    truncateText,
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async fetchCollection () {
      try {
        this.showLoading = true;
        const response = await collectionApi.getCollection(this.collection.id);
        this.collection = response.data.data;

        const field = this.collection.config.fields[0];
        this.collectionField = field
          ? {
            key: field._id ?? field.config._id,
            label: field.title ?? field.config.title,
            sortable: true,
            type: field.type,
            is_dynamic_generated: true,
          }
        : null;

      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料集錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async fetchCollectionItem() {
      try {
        this.showLoading = true;
        const response = await collectionApi.getItem(this.collection.id, this.collectionItem.id);

        if (this.collectionField != null) {
          this.breadCrumbName = response.data.data.data.find(
            (item) => item.field_id === this.collectionField.key
          )?.value || "";

          this.branchId   = response.data.data.branch.id;
          this.branchName = response.data.data.branch.name;
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料集錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async fetchItemStaffs() {
      try {
        this.showLoading = true;
        const response = await collectionApi.getCollectionItemStaffs(this.collection.id, this.collectionItem.id, {
          keyword: this.keyword,
          per_page: this.perPage,
          page: this.currentPage,
          branch_id: this.branchId,
        });

        this.items = response.data.data.map((item) => {
          return {
            ...item,
            selected: false
          };
        });

        this.totalRows = response.data.meta.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料集項目錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async addStaff(staff) {
      if (staff !== null) {
        const now = new Date();
        staff.bound_at = now.getFullYear() + "-" +
          String(now.getMonth() + 1).padStart(2, "0") + "-" +
          String(now.getDate()).padStart(2, "0") + " " +
          String(now.getHours()).padStart(2, "0") + ":" +
          String(now.getMinutes()).padStart(2, "0") + ":" +
          String(now.getSeconds()).padStart(2, "0");

        try {
          this.showLoading = true;

          // 將 item.data 組起來
          let data = staff;

          data = {
            ...data,
            is_enabled: true,
          };

          await collectionApi.createCollectionItemStaff(this.collection.id, this.collectionItem.id, data);
          this.$swal("新增成功", "", "success")
            .then(() => {
              this.fetchItemStaffs();
            })

          this.customer = {};
          return true;
        } catch (error) {
          console.error(error);
          this.$swal("錯誤", "儲存失敗", "error");
          return false;
        } finally {
          this.showLoading = false;
        }
      }
    },
    async handleDelete(item) {
      this.showLoading = true;
      const result = await this.$swal({
        title:`確定刪除${item.name}嗎`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonColor: "#3085D7",
        cancelButtonText: "取消",
        confirmButtonColor: "#DD3333",
        confirmButtonText: "確定刪除",
        reverseButtons: true,
      });

      if (!result.value) {
        this.showLoading = false;
        return;
      }

      await collectionApi.deleteCollectionItemStaff(item.collection_id, item.item_id, item.collection_relation_id);
      this.$swal("刪除成功", "", "success");

      this.fetchItemStaffs();
    },
    toggleSelectAll() {
      this.items.forEach((item) => (item.selected = this.selectAll));
    },
    async changeRelationIsEnabled() {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "調整更新狀態",
          text: `調整所選項目狀態為？`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "狀態：關閉",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "狀態：啟用",
          reverseButtons: true,
          showCloseButton: true, // 顯示右上角的關閉按鈕
        })

        if (result.value || result.dismiss === 'cancel') { // 按啟用 || 按啟用
          const response = await collectionApi.changeRelationIsEnabled(
              this.collection.id,
              {
                item_ids: this.selectedItems,
                is_enabled: result.value ?? false
              }
          );
          if (response.status === 200) {
            this.$swal('成功', '更改狀態成功', 'success');
            await this.fetchItemStaffs();
          }
        }
      } catch (error) {
        console.error(error);
        this.$swal("失敗", error.response.data.message, "更改狀態失敗，請稍候再試");
      } finally {
        this.showLoading = false;
      }
    }
  }
}
</script>

<style>
.custom-label {
  display: inline-flex; /* 只控制文字部分 */
  align-items: center; /* 垂直置中 */
  height: 100%; /* 繼承容器高度 */
  line-height: normal; /* 確保文字正常顯示 */
}
</style>
